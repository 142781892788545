import { SET_SCORES } from 'constants/actionTypes';
import * as scoresApi from '../api/scores';

export const getScores = () => async (dispatch) => {
  try {
    const scores = await scoresApi.getScores();
    // const mappedScores = mapScores(scores.data);

    dispatch({ type: SET_SCORES, payload: scores.data });
  } catch (error) {
    console.log(error);
  }
};
