import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

const LeagueStats = ({teams}) => {
  const stats = () => {
    const sortedTeams = teams.sort((team1, team2) => team2.weekStartBalance - team1.weekStartBalance);
    return (
      sortedTeams.map((team) => {
        if (!team.weekChangeData?.stats) {return <></>}
        const {points, over, under} = team.weekChangeData?.stats || {};
        return <TableRow
              key={team._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{team.teamName}</TableCell>
              <TableCell align="center">{`${points.W || 0}-${points.L || 0}${points.T ? `-${points.T}` : ''}`}</TableCell>
              <TableCell align="center">{`${over.W || 0}-${over.L || 0}${over.T ? `-${over.T}` : ''}`}</TableCell>
              <TableCell align="center">{`${under.W || 0}-${under.L || 0}${under.T ? `-${under.T}` : ''}`}</TableCell>
            </TableRow>
      })
    );
  }; 

  return (
    <div>
      <TableContainer sx={{marginBottom: '20px'}} component={Paper}>
        <Table size="small" aria-label="standings">
          <TableHead>
            <TableRow>
            <TableCell align="left">Team</TableCell>
              <TableCell align="center">Spread</TableCell>
              <TableCell align="center">Over</TableCell>
              <TableCell align="center">Under</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {stats()}
          </TableBody>
        </Table>
      </TableContainer>
   
    </div>
  );
};

export default LeagueStats;
